<template>
<div>
  <!----- 搜索框 start------->
  <el-form :inline="true" class="search">
    <el-form-item label="名称">
      <el-input v-model="searchData.name" placeholder="名称" clearable />
    </el-form-item>
    <el-form-item>
      <el-button @click="getGroups()">查询</el-button>
    </el-form-item>
  </el-form>
  <!----- 搜索框 end------->

  <!----- 表格 start------->
  <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
    <el-table-column prop="id" label="ID"  />
    <el-table-column prop="name" label="名称">
      <template #default="scope">
        {{ scope.row.name }}
        <el-tag type="danger" v-if="scope.row.type > 1">{{ scope.row.type_desc }}</el-tag>
        <el-tag type="info" v-else>{{ scope.row.type_desc }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="expired_date" label="有效期" />
    <el-table-column prop="member_count" label="成员数" />
    <el-table-column prop="pet_count" label="宠物数" />
    <el-table-column prop="created_at" label="创建时间" />
    <el-table-column label="操作">
      <template #default="scope">
        <el-button type="primary" size="small" @click="clickUpdate(scope.row)">修改</el-button>
      </template>
    </el-table-column>
  </el-table>

  <!----- 表格 end------->


  <!----- 分页 start------->
  <div class="cherry-page" v-show="searchData.total">
    <el-pagination
        small
        background
        layout="prev, pager, next, total"
        :total="searchData.total"
        :page-size="searchData.per_page"
        v-model:current-page="searchData.page"
        @update:current-page="getGroups()"
        class="mt-4"
    />
  </div>
  <!----- 分页 end------->
</div>
</template>

<script>
import {apiGetGroups} from '@/api/memberApi'
  export default {
    created() {
      this.getGroups()
    },
    data () {
      return {
        searchData:{
          name: '',
          total: 0,
          page: 1,
          per_page: 15,
        },
        tableData:[],
      }
    },
    methods:{
      getGroups () {
        apiGetGroups(this.searchData).then(res => {
          this.tableData = res.data
          this.searchData.total = res.meta.total
        })
      },
      clickUpdate (user) {
        console.log(user)
      }
    }
  }
</script>

<style scoped>

</style>
